import React, { Component } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import facebook from '../../images/facebook.svg';
import youtube from '../../images/youtube.png';
import map from '../../images/map.png';
import { Button, Pane} from 'evergreen-ui';
import './footer.css';
let paneWidth  = (window.screen.width<480)?250:400;
class Footer extends Component {
    state = {  }
    handleMap(){
      console.log("begin button");
      let gmap = document.getElementById("gmap");
      gmap.className = "show";
    }
    closeMap(){
      let gmap = document.getElementById("gmap");
      gmap.classList.remove("show");
    }
    render() { 
        return ( 
            <footer id="footer">
               <div   id="gmap">
                 <Pane
                    elevation={3}
                    height={220}
                    style={{'background':'#fff','width':`${paneWidth}px`, 'fontFamily':'poppins'}}
                >
                  <Row>
                    <Col xs={1}/>
                    <Col xs={10}>
                    <br/>
                    <br/>
                    <a href="https://www.google.com/maps/place/Dr+Varsha+Galgali/@18.5039005,73.8210983,17z/data=!4m5!3m4!1s0x3bc2bf8ea78c9857:0x4ca60651114b8c7e!8m2!3d18.5039086!4d73.821125?hl=en" style={{'textDecoration':'none','color':'black','font-weight':'bold'}} >Kothrud Branch -{'>'}</a>
                 
                    <hr id="headingHR3"/>
                  
                    <a href="https://www.google.com/maps/place/Aarogyasarsthi+Ayurved+Clinic/@18.5153248,73.8476401,19z/data=!3m1!4b1!4m5!3m4!1s0x3bc2c1bd922a8851:0xa38a7368d9abb937!8m2!3d18.5153248!4d73.8481873?hl=en" style={{'textDecoration':'none','color':'black','font-weight':'bold'}} >Narayan Peth Branch -{'>'}</a>
                    </Col>
                    <Col xs={1}/>
                  </Row>
                  
                    <br/>
                    <Row style={{'textAlign':'center'}}>
                      <Col xs={3}/>
                      <Col xs={6}>
                    <Button intent="danger" style={{'width':'100%'}} onClick={this.closeMap.bind(this)}>Close</Button>
                    </Col>
                    <Col xs={3}/>
                    </Row>
                </Pane>
                </div>
           
                 <Row >
              <Col  xs={{span:8,offset:2}} sm={{span:3,offset:1}} style={{'margin-top':'20px','text-align':'center'}}>
                <h5 style={{'color':'#000000','font-family':'Raleway', 'font-weight':'bold'}}>Get to Know us</h5>
                <h6 style={{'color':'#000000','font-family':'source sans pro', 'font-weight':'bold'}}><a href="/aboutus" style={{'color':'#000000','text-decoration':'none'}}>About us</a></h6>
                <h6 style={{'color':'#000000','font-family':'source sans pro', 'font-weight':'bold'}}><a href="https://firebasestorage.googleapis.com/v0/b/arogyasarthichikitsalya.appspot.com/o/t%26c(2-4-22).docx?alt=media&token=a18cd3e2-2bd4-40b1-9ae2-862b2bbc3a1c" style={{'color':'#000000','text-decoration':'none'}}>Terms And Conditions</a></h6>
                <h6 style={{'color':'#000000','font-family':'source sans pro', 'font-weight':'bold'}}><a href="https://firebasestorage.googleapis.com/v0/b/arogyasarthichikitsalya.appspot.com/o/Privacy%20Policy%20for%20Arogya%20Sarthi%20Chikitsalaya(2-4-22).docx?alt=media&token=b1fe2b4a-6ac5-4388-bc65-08543f029229" style={{'color':'#000000','text-decoration':'none'}}>Privacy Policy</a></h6>
                <h6 style={{'color':'#000000','font-family':'source sans pro', 'font-weight':'bold'}}><a href="/ourPhysicians" style={{'color':'#000000','text-decoration':'none'}}>Our Physicians</a></h6>
              </Col>
              <Col  xs={{span:8,offset:2}} sm={{span:3,offset:1}} style={{'margin-top':'20px','text-align':'center'}}>
                <h5 style={{'color':'#000000','font-family':'Raleway', 'font-weight':'bold'}}>Help</h5>
                <h6 style={{'color':'#000000','font-family':'source sans pro', 'font-weight':'bold'}}><a href="/contact" style={{'color':'#000000','text-decoration':'none'}}>Contact Us</a></h6>
            {  /*  <h6 style={{'color':'#000000','font-family':'source sans pro', 'font-weight':'bold'}}><a href="/" style={{'color':'#000000','text-decoration':'none'}}>Resources</a></h6> */ }
              </Col>
              <Col  xs={{span:8,offset:2}} sm={{span:3,offset:1}} style={{'margin-top':'20px','text-align':'center'}}>
             
              <h5 style={{'color':'#000000','font-family':'Raleway', 'font-weight':'bold'}}>Get Social with us</h5>
              <Row style={{'justify-content':'center'}}>
               
                <Col xs={3} sm={2} >
                  <a href="https://www.facebook.com/arogyasarthi/" target="_blank" rel="noopener noreferrer" >
                 <img src={facebook} alt="why"  width={40} height={40} />
                </a>
                </Col>
                <Col xs={3} sm={2}>
                <Button  appearance="minimal" onClick={this.handleMap.bind(this)} id="FSMIcons" ><img src={map} id="FSMIcons" alt="socialMedia" /></Button>
                </Col>
                <Col xs={3} sm={2} >
                  <a href="https://www.youtube.com/" target="_blank" rel="noopener noreferrer" >
                 <img src={youtube} alt="why"  width={40} height={40} />
                </a>
                </Col>
                </Row>
              </Col>
            </Row>
            <hr style={{'border-top':'1px solid black','padding-bottom':'0px'}}/>
            <Row  style={{'justify-content':'center','font-family':'source sans pro','color':'#000000','overflow': 'hidden','font-size':'15px','font-weight':'bold'}}>
            ©Copyright 2021 Aarogya Sarthi Chikitsalya
            </Row>
            <br/>

            </footer>
         );
    }
}
 
export default Footer;