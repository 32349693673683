import './App.css'
import React, { Component,lazy,Suspense } from 'react';
import MainNavbar from './components/navbar/navbar';
import AOS from 'aos';
import {BrowserRouter as Router,Switch,Route} from "react-router-dom";
import loading from './images/spinner.svg';
import Footer from './components/footer/footer';
const ForPracticioners = lazy(() => import('./components/forPracticioners/forPracticioners'));
const Home = lazy(() => import('./components/home/home'));
const Login = lazy(() => import('./components/login/login'));
const SignUp = lazy(() => import('./components/signup/signup'));
const HealthConservation = lazy(() => import('./components/healthConservation/healthConservation'));
const Article = lazy(()=>import('./components/healthConservation/article'));
const OurPhysicians = lazy(()=>import('./components/ourPhysicians/ourPhysicians.js'));
const FormPage = lazy(()=>import('./components/forPracticioners/formPage.js'));
const AudioLecture  = lazy(()=>import('./components/forPracticioners/audioLecture.js'));
const VideoLecture  = lazy(()=>import('./components/forPracticioners/videoLecture.js'));
const ForgotPassword = lazy(()=>import('./components/forgotPassword/forgotPassword.js'));
const AboutUs = lazy(()=>import('./components/aboutUs/aboutus.js'));
const Contact = lazy(()=>import('./components/contact/contact.js'));
const Testimonials = lazy(()=>import('./components/testimonials/testimonials.js'));
const Gallery = lazy(()=>import('./components/aboutUs/gallery.js'));
const Panchkarma = lazy(() => import('./components/panchkarma/panchkarma.js'));
const Vamana = lazy(() => import('./components/panchkarma/vamana.js'));
const Virechna = lazy(() => import('./components/panchkarma/virechana.js'));
const Basti = lazy(() => import('./components/panchkarma/basti.js'));
const Nasya = lazy(() => import('./components/panchkarma/nasya.js'));
const Rakta = lazy(() => import('./components/panchkarma/rakta.js'));
class App extends Component {
  state = {  }
  componentDidMount() {
    // or simply just AOS.init();
    AOS.init({
      // initialise with other settings
      duration : 3000
    });
  }
  render() { 
    return(
     <div id="mainDivApp">
     <MainNavbar></MainNavbar>
     <Router>
        <Suspense fallback={<img src={loading} alt='loader' style={{'position':'fixed','left':`${window.screen.width/2 - 60}px`,'top':`${window.screen.height/2 - 80}px`,'height':'150px','width':'150px','zIndex':'5'}}></img>}>
          <Switch>
            <Route exact path="/" render={(props)=><Home {...props} />}/>
            <Route exact path="/login" render={(props)=><Login {...props} />}/>
            <Route exact path="/signup" render={(props)=><SignUp {...props} />}/>
            <Route exact path="/healthConservation" render={(props)=><HealthConservation {...props} />}/>
            <Route exact path="/article" render={(props)=><Article {...props}/>}/>
            <Route exact path="/ourPhysicians" render={(props)=><OurPhysicians {...props}/>}/>
            <Route exact path="/formPage" render={(props)=><FormPage {...props}/>}/>
            <Route exact path="/forPracticioners" render={(props)=><ForPracticioners {...props}/>}/>
            <Route exact path="/audioLecture" render={(props)=><AudioLecture {...props}/>}/>
            <Route exact path="/videoLecture" render={(props)=><VideoLecture {...props}/>}/>
            <Route exact path="/forgotPassword" render={(props)=><ForgotPassword {...props}/>}/>
            <Route exact path="/aboutus" render={(props)=><AboutUs {...props}/>}/>
            <Route exact path="/contact" render={(props)=><Contact {...props}/>}/>
            <Route exact path="/testimonials" render={(props)=><Testimonials {...props}/>}/>
            <Route exact path="/gallery" render={(props)=><Gallery {...props}/>}/>
            <Route exact path="/panchkarma" render={(props)=><Panchkarma {...props}/>}/>
            <Route exact path="/vamana" render={(props)=><Vamana {...props}/>}/>
            <Route exact path="/virechna" render={(props)=><Virechna {...props}/>}/>
            <Route exact path="/basti" render={(props)=><Basti {...props}/>}/>
            <Route exact path="/nasya" render={(props)=><Nasya {...props}/>}/>
            <Route exact path="/rakta" render={(props)=><Rakta {...props}/>}/>
          </Switch>
        </Suspense>
      </Router>
     
      <Footer/>
     </div>
    );
  }
}
 
export default App;
