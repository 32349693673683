import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import React, { Component,useState } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Row from 'react-bootstrap/Row';
import { Button, Pane} from 'evergreen-ui';
import facebook from '../../images/facebook.svg';
import logo from '../../images/logo.svg';
import youtube from '../../images/youtube.png';
import map from '../../images/map.png';
import './navbar.css';

let paneWidth  = (window.screen.width<480)?250:400;

class Example extends Component{
    state={show:false};
    handleShow(){
      this.setState({show:true});
    }
    handleClose(){
      this.setState({show:false});
    }
    flow(){
      console.log(window.sessionStorage.getItem("token"));
      if(window.sessionStorage.getItem("token")!=null){
        if(window.sessionStorage.getItem("isPracticioner")=="true"){
        window.location.href="/forPracticioners";
        }
        else if(window.sessionStorage.getItem("isPracticioner")=="pending"){
          alert("Your Practicioner form was submitted successfully and is currently being reviewed, you will be informed through email when you get access to Practicioner page.");
        }
        else{
          window.location.href="/formPage";
        }
      }
      else{
        window.sessionStorage.setItem("didTryPracticioner","true");
        window.location.href="/login";
      }
      
    }
    
    render(){
    return (
      <>
        <Button  onClick={this.handleShow.bind(this)} appearance="minimal" id="menuButton">
          <img src="https://firebasestorage.googleapis.com/v0/b/arogyasarthichikitsalya.appspot.com/o/menu.png?alt=media&token=4cce83ea-e05f-495f-ad21-0053921106d4" alt="hamburger" id="hamburger"/>
        </Button>
  
        <Offcanvas show={this.state.show} onHide={this.handleClose.bind(this)}>
          <Offcanvas.Header closeButton>
            <Offcanvas.Title id="offcanvas__Header" >Hello User</Offcanvas.Title>
            <hr/>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <ul id="nav__list" > 
            <li className="off_list">
                <a href="/" style={{'textDecoration':'none','color':'black'}}>Home</a>
                </li>
            <li className="off_list">
                <a href="/aboutus" style={{'textDecoration':'none','color':'black'}}>About Us</a>
                </li>
                <li className="off_list">
                <a href="/ourPhysicians" style={{'textDecoration':'none','color':'black'}}>Our Team</a>
                </li>
                <li className="off_list">
                <a href="/healthConservation" style={{'textDecoration':'none','color':'black'}}>Health Conservation</a>
                </li>
            {/*    <li className="off_list">
                <a href="/" style={{'textDecoration':'none','color':'black'}}>Treatment & Disease</a>
                </li>
    */}
                <li className="off_list">
                <a href="/panchkarma" style={{'textDecoration':'none','color':'black'}}>Panchkarma</a>
                </li>
              
               
               
                <li className="off_list" > 
                    <Button intent="minimal" id="forPracticioners" onClick={this.flow}  >For Practicioners</Button>
                </li>
                <li className="off_list">
                <a href="/contact" style={{'textDecoration':'none','color':'black'}}>Contact Us</a>
                </li>
            </ul>
          </Offcanvas.Body>
        </Offcanvas>
      </>
    );
    }
  }
class MainNavbar extends Component {
    state = {  }

    handleMap(){
      console.log("begin button");
      let gmap = document.getElementById("gmap");
      gmap.className = "show";
    }
    closeMap(){
      let gmap = document.getElementById("gmap");
      gmap.classList.remove("show");
    }
    
    flow(e){
      e.preventDefault();
      console.log(window.sessionStorage.getItem("token"));
      console.log(window.sessionStorage.getItem("isPracticioner"));
      if(window.sessionStorage.getItem("token")!=null){
        if(window.sessionStorage.getItem("isPracticioner")=="true" || window.sessionStorage.getItem("isPracticioner")==true){
          window.location.href="/forPracticioners";
          }
          else{
            window.location.href="/formPage";
          }
      }
      else{
        window.sessionStorage.setItem("didTryPracticioner","true");
        window.location.href="/login";
      }
      
    }
    render() { 
        return ( 
            <>
            <div   id="gmap">
                 <Pane
                    elevation={3}
                    height={220}
                    style={{'background':'#fff','width':`${paneWidth}px`, 'fontFamily':'poppins'}}
                >
                  <Row>
                    <Col xs={1}/>
                    <Col xs={10}>
                    <br/>
                    <br/>
                    <a href="https://www.google.com/maps/place/Dr+Varsha+Galgali/@18.5039005,73.8210983,17z/data=!4m5!3m4!1s0x3bc2bf8ea78c9857:0x4ca60651114b8c7e!8m2!3d18.5039086!4d73.821125?hl=en" style={{'textDecoration':'none','color':'black','font-weight':'bold'}} >Kothrud Branch -{'>'}</a>
                 
                    <hr id="headingHR3"/>
                  
                    <a href="https://www.google.com/maps/place/Aarogyasarsthi+Ayurved+Clinic/@18.5153248,73.8476401,19z/data=!3m1!4b1!4m5!3m4!1s0x3bc2c1bd922a8851:0xa38a7368d9abb937!8m2!3d18.5153248!4d73.8481873?hl=en" style={{'textDecoration':'none','color':'black','font-weight':'bold'}} >Narayan Peth Branch -{'>'}</a>
                    </Col>
                    <Col xs={1}/>
                  </Row>
                  
                    <br/>
                    <Row style={{'textAlign':'center'}}>
                      <Col xs={3}/>
                      <Col xs={6}>
                    <Button intent="danger" style={{'width':'100%'}} onClick={this.closeMap.bind(this)}>Close</Button>
                    </Col>
                    <Col xs={3}/>
                    </Row>
                </Pane>
                </div>
                <Navbar id="main__nav">
                <Container>
                    <Navbar.Brand href="/" >
                        <img src={logo} id="navImglogo" alt="logo"  />
                    
                    </Navbar.Brand>
                    <img src="https://firebasestorage.googleapis.com/v0/b/arogyasarthichikitsalya.appspot.com/o/Untitled-1.svg?alt=media&token=05bb071f-969f-4c09-8c01-e86f99975e9d" alt="title" id="navHeadingTitle"/>
                    <Example id="menu__ListMobile"/>
                    <Row id="mainNavRowSocialMedia"> 
                    <Button  appearance="minimal" onClick={this.handleMap.bind(this)} id="SMIcons"><img src={map} id="SMIcons" alt="socialMedia" /></Button>
                    <a href="https://www.youtube.com/" id="SMIcons"><img src={youtube} alt="socialMedia" id="SMIcons"/>
                    </a>
                    <a href="https://www.facebook.com/arogyasarthi/" id="SMIcons"><img src={facebook} alt="socialMedia" id="SMIcons"/></a>
                    
                 
                </Row>
                   </Container>
                </Navbar>
                <Navbar id="second__Nav">
                  <Row id="second__NavRow">
                  <Col xs={1}/>
                  <Col xs={2} >
                    <a href="/aboutus" style={{'textDecoration':'none','color':'black','fontSize':'18px'}}>About Us</a>
                    </Col>
                    <Col xs={2}>
                    <a href="/ourPhysicians" style={{'textDecoration':'none','color':'black','fontSize':'18px'}}>Our Team</a>
                    </Col>
                    <Col xs={2} >
                    <a href="/" style={{'textDecoration':'none','color':'black','fontSize':'18px'}}>Home</a>
                    </Col>
                    <Col xs={2}>
                    <a href="/panchkarma" style={{'textDecoration':'none','color':'black','fontSize':'18px'}}>Panchkarma</a>
                    </Col>
                  
                    <Col xs={2} >
                    <a href="/contact" style={{'textDecoration':'none','color':'black','fontSize':'18px'}}>Contact Us</a>
                    </Col>
                    <Col xs={1}/>    
                  
                   
                  
                  </Row>
                  
                </Navbar>
                
        <Navbar  id="third__Nav">
        <Row id="second__NavRow">
                    <Col xs={3}/>
                    
                    <Col xs={2} >
                                <a href="/healthConservation" style={{'textDecoration':'none','color':'black','fontSize':'18px'}}>Health Conservation</a>
                                </Col>
                                <Col xs={2}/>
                    <Col xs={2} style={{'fontSize':'18px'}}>
                    <Button intent="minimal" id="forPracticioners" onClick={this.flow.bind(this)}>For Practicioners</Button>
                    </Col>
                    {/*
                    <Col xs={2} >
                    <a href="/weeklyQuotes" style={{'textDecoration':'none','color':'black','fontSize':'18px'}}>Treatment & Diseases</a>
                    </Col>
        */}
                    <Col xs={3}/>
          </Row>
        </Navbar>
                </>
         );
    }
}
 
export default MainNavbar;